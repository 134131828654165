import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from '@ngrx/store';
import { InfoPostWebsiteEffects } from "app/store/infopost/infopost.effects";
import { InfoPostFeatureKey, infoPostMetaReducers, infoPostReducer } from 'app/store/infopost/infopost.reducers';
import { InformationalWebLabels, MenuItems, NoticeType, NoticeCategory } from "./informational-posting-web.utils";
import { allowCBH, featureFlagGuard } from "app/core/routeGuards/feature-flag.guard";

const InfopostWebsiteRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('app/modules/infopost/pages/infopost-main/infopost-main.module').then(m => m.InfoPostMainModule),
    },
    {
        path: 'informational-posting',
        loadChildren: () => import('app/modules/infopost/pages/infopost-home/infopost-home.module').then(m => m.InfopostWebHomeModule),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'capacity/operationally-available',
        loadComponent: () => import('app/modules/infopost/pages/capacity/capacity-shared-table/capacity-shared-table.component').then(m => m.CapacitySharedTableComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.OPERATIONALCAPACITY,
            title: InformationalWebLabels.OPERATIONALLYLABEL
        },
    },
    {
        path: 'capacity/unsubscribed',
        loadComponent: () => import('app/modules/infopost/pages/capacity/capacity-shared-table/capacity-shared-table.component').then(m => m.CapacitySharedTableComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.UNSUBSCRIBEDCAPACITY,
            title: InformationalWebLabels.UNSUBSCRIBEDLABEL
        },
    },
    {
        path: 'capacity/no-notice-activity',
        loadComponent: () => import('app/modules/infopost/pages/capacity/capacity-shared-table/capacity-shared-table.component').then(m => m.CapacitySharedTableComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.NONOTICEACTIVITY,
            title: InformationalWebLabels.NONOTICELABEL
        },
    },
    {
        path: 'capacity/unsubscribed-FTS-A',
        loadComponent: () => import('app/modules/infopost/pages/capacity/capacity-shared-table/capacity-shared-table.component').then(m => m.CapacitySharedTableComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.UNSUBSCRIBEDCAPACITYFTSA,
            title: InformationalWebLabels.UNSUBSCRIBED_FTS_ALABEL
        },
    },
    {
        path: 'capacity/scheduling-constrained-areas',
        loadComponent: () => import('app/modules/infopost/pages/capacity/capacity-shared-table/capacity-shared-table.component').then(m => m.CapacitySharedTableComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.SCHEDULINGCONSTRAINEDAREAS,
            title: InformationalWebLabels.SCHEDULING_CONSTRAINEDLABEL
        },
    },
    {
        path: 'capacity/scheduling-group-available',
        loadComponent: () => import('app/modules/infopost/pages/capacity/capacity-shared-table/capacity-shared-table.component').then(m => m.CapacitySharedTableComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.SCHEDULINGGROUPAVAILABLECAPACITY,
            title: InformationalWebLabels.SCHEDULING_GROUPLABEL
        },
    },
    {
        path: 'notices/critical',
        data: {
            menuItem: NoticeCategory.critical,
            title: InformationalWebLabels.CRITICALLABEL,
        },
        loadChildren: () => import('app/modules/infopost/pages/notices/pages/critical/critical.module').then(m => m.CriticalModule),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'notices/non-critical',
        data: {
            menuItem: NoticeCategory.nonCritical,
            title: InformationalWebLabels.NONCRITICALLABEL,
        },
        loadChildren: () => import('app/modules/infopost/pages/notices/pages/critical/critical.module').then(m => m.CriticalModule),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'notices/planned-service-outage',
        data: {
            menuItem: NoticeCategory.plannedServiceOutage,
            title: InformationalWebLabels.PLANNEDSERVICEOUTAGES,
        },
        loadChildren: () => import('app/modules/infopost/pages/notices/pages/critical/critical.module').then(m => m.CriticalModule),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'notices/request-to-purchase-releasable-capacity',
        data: {
            title: InformationalWebLabels.REQUESTTOPURCHASERELEASABLECAPACITY,
            noticeTypeID: NoticeType.RELEASE_CAPACITY_NOTICE_TYPE_ID
        },
        loadChildren: () => import('app/modules/infopost/pages/notices/pages/critical/critical.module').then(m => m.CriticalModule),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'notices/waste-heat',
        data: {
            menuItem: NoticeCategory.wasteHeat,
            title: InformationalWebLabels.WASTEHEAT,
        },
        loadChildren: () => import('app/modules/infopost/pages/notices/pages/critical/critical.module').then(m => m.CriticalModule),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'notices/capacity-release-indices',
        data: {
            title: InformationalWebLabels.CAPACITYRELEASEINDICES,
        },
        loadComponent: () => import('app/modules/infopost/pages/notices/pages/capacity-release-indices/capacity-release-indices.component').then(m => m.CapacityReleaseIndicesComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/department-contacts',
        loadChildren: () => import('app/modules/infopost/pages/departmental-contact/departmental-contact.module').then(m => m.DepartmentalContactModule),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/forms',
        loadComponent: () => import('app/modules/infopost/pages/forms/forms.component').then(m => m.FormsComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'gas-quality/gas-quality-report',
        loadComponent: () => import('app/modules/infopost/pages/gas-quality/gas-quality-report/gas-quality-report.component').then(m => m.GasQualityReportComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'gas-quality/gas-quality-tariff-provisions',
        loadComponent: () => import('app/modules/infopost/pages/gas-quality/gas-quality-tariff-provisions/gas-quality-tariff-provisions.component').then(m => m.GasQualityTariffProvisionsComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'gas-quality/gas-quality-procedures',
        loadComponent: () => import('app/modules/infopost/pages/gas-quality/gas-quality-procedure/gas-quality-procedure.component').then(m => m.GasQualityProcedureComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'gas-quality/posted-gas-quality-specifications',
        loadComponent: () => import('app/modules/infopost/pages/gas-quality/gas-quality-pdf-view/gas-quality-pdf-view.component').then(m => m.GasQualityPdfViewComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.POSTEDGASQUALITYSPECIFICATIONS,
            title: InformationalWebLabels.POSTEDGASQUALITYSPECIFICATIONS
        }
    },
    {
        path: 'gas-quality/safe-harbor-tariff-provisions',
        loadComponent: () => import('app/modules/infopost/pages/gas-quality/gas-quality-pdf-view/gas-quality-pdf-view.component').then(m => m.GasQualityPdfViewComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.SAFEHARBORTARIFFPROVISIONS,
            title: InformationalWebLabels.SAFEHARBORTARIFFPROVISION
        }
    },
    {
        path: 'gas-quality/communication-protocol',
        loadComponent: () => import('app/modules/infopost/pages/gas-quality/gas-quality-pdf-view/gas-quality-pdf-view.component').then(m => m.GasQualityPdfViewComponent),
        canActivate: [featureFlagGuard],
        data: {
            infoPostID: MenuItems.COMMUNICATIONPROTOCOL,
            title: InformationalWebLabels.COMMUNICATIONPROTOCOL
        }
    },
    {
        path: 'gas-quality/receipt-point-gas-composition',
        loadChildren: () =>
            import('app/modules/infopost/pages/coastal-bend-header/pages/receipt-point-gas-composition/receipt-point-gas-composition.module').then(
                m => m.ReceiptPointGasCompositionModule
            ),
        canActivate: [featureFlagGuard, allowCBH],
        data: {
            type: 'receipt',
        },
    },
    {
        path: 'gas-quality/stratton-ridge-gas-composition',
        loadChildren: () =>
            import('app/modules/infopost/pages/coastal-bend-header/pages/receipt-point-gas-composition/receipt-point-gas-composition.module').then(
                m => m.ReceiptPointGasCompositionModule
            ),
        canActivate: [featureFlagGuard, allowCBH],
        data: {
            type: 'stratton',
        },
    },
    {
        path: 'gas-quality/historical-gas-quality',
        loadChildren: () =>
            import('app/modules/infopost/pages/coastal-bend-header/pages/historical-gas-quality/historical-gas-quality.module').then(
                m => m.HistoricalGasQualityModule
            ),
        canActivate: [featureFlagGuard, allowCBH],
    },
    {
        path: 'informational-posting/index-of-customers',
        loadComponent: () => import('app/modules/infopost/pages/index-of-customers/index-of-customers.component').then(m => m.IndexOfCustomersComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/locations',
        loadComponent: () => import('app/modules/infopost/pages/locations/locations.component').then(m => m.LocationsComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/posted-imbalances',
        loadComponent: () => import('app/modules/infopost/pages/posted-imbalances/posted-imbalances.component').then(m => m.PostedImbalancesComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/tariff-filings',
        loadComponent: () => import('app/modules/infopost/pages/regulatory/tariff-filings/tariff-filings.component').then(m => m.TariffFilingsComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'transactional-reporting/capacity-release',
        loadComponent: () => import('app/modules/infopost/pages/transactional-reporting/capacity-release/capacity-release.component').then(m => m.CapacityReleaseComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'transactional-reporting/firm-services',
        loadComponent: () => import('app/modules/infopost/pages/transactional-reporting/firm-services/firm-services.component').then(m => m.FirmServicesComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'transactional-reporting/interruptible-services',
        loadComponent: () => import('app/modules/infopost/pages/transactional-reporting/interruptible-services/interruptible-services.component').then(m => m.InterruptibleServicesComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'transactional-reporting/manual-releases',
        loadComponent: () => import('app/modules/infopost/pages/transactional-reporting/manual-releases/manual-releases.component').then(m => m.ManualReleasesComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/site-map',
        loadComponent: () => import('app/modules/infopost/pages/site-map/site-map.component').then(x => x.SiteMapComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/training',
        loadComponent: () => import('app/modules/infopost/pages/training/training.component').then(m => m.TrainingComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/downloads',
        loadComponent: () => import('app/modules/infopost/pages/downloads/downloads.component').then(m => m.DownloadsComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/standards-of-conduct',
        loadComponent: () => import('app/modules/infopost/pages/standards-of-conduct/standards-of-conduct.component').then(m => m.StandardsOfConductComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'tariff/title-sheet-section',
        data: {
            infoPostID: MenuItems.TARIFFTITLESHEETSECTION,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'tariff/table-of-contents',
        data: {
            infoPostID: MenuItems.TARIFFTABLEOFCONTENTS,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'tariff/preliminary-statement',
        data: {
            infoPostID: MenuItems.TARIFFPRELIMINARYSTATEMENT,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'tariff/currently-effective-rates',
        data: {
            infoPostID: MenuItems.TARIFFCURRENTLYEFFECTIVERATES,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'tariff/rate-schedules',
        data: {
            infoPostID: MenuItems.TARIFFRATESCHEDULE,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'tariff/general-terms-and-conditions',
        data: {
            infoPostID: MenuItems.TARIFFGENERALTERMSANDCONDITIONS,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard],
    },
    {
        path: 'tariff/form-of-service-agreement',
        data: {
            infoPostID: MenuItems.TARIFFFORMSOFSERVICEAGREEMENTS,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard],
    },
    {
        path: 'tariff/entire-tariff',
        data: {
            infoPostID: MenuItems.TARIFFDOWNLOAD,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard],
    },
    {
        path: 'tariff/sheet-section-index',
        data: {
            infoPostID: MenuItems.TARIFFSHEETSECTIONINDEX,
        },
        loadComponent: () => import('app/modules/infopost/pages/tariff/title-sheet-section/title-sheet-section.component').then(m => m.TitleSheetSectionComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'tariff/map',
        loadComponent: () => import('app/modules/infopost/pages/tariff/tariff-map/tariff-map.component').then(m => m.TariffMapComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'search/entire-tariff',
        data: {
            infoPostID: MenuItems.TARIFFDOWNLOAD,
        },
        loadComponent: () => import('app/modules/infopost/pages/search/entire-tariff-pdf/entire-tariff-pdf.component').then(m => m.EntireTariffPDFComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'search/rate-schedule',
        data: {
            infoPostID: MenuItems.TARIFFRATESCHEDULE,
        },
        loadComponent: () => import('app/modules/infopost/pages/search/entire-tariff-pdf/entire-tariff-pdf.component').then(m => m.EntireTariffPDFComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'search/general-terms-and-conditions',
        data: {
            infoPostID: MenuItems.TARIFFGENERALTERMSANDCONDITIONS,
        },
        loadComponent: () => import('app/modules/infopost/pages/search/entire-tariff-pdf/entire-tariff-pdf.component').then(m => m.EntireTariffPDFComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'search/form-of-service-agreements',
        data: {
            infoPostID: MenuItems.TARIFFFORMSOFSERVICEAGREEMENTS,
        },
        loadComponent: () => import('app/modules/infopost/pages/search/entire-tariff-pdf/entire-tariff-pdf.component').then(m => m.EntireTariffPDFComponent),
        canActivate: [featureFlagGuard]
    },
    {
        path: 'informational-posting/rates',
        loadComponent: () => import('app/modules/infopost/pages/rates/rates.component').then(m => m.RatesComponent),
        canActivate: [featureFlagGuard]
    },

];

@NgModule({
    declarations: [

    ],
    imports: [
        RouterModule.forChild(InfopostWebsiteRoutes),
        StoreModule.forFeature(InfoPostFeatureKey, infoPostReducer, {
            metaReducers: infoPostMetaReducers
        }),
        EffectsModule.forFeature([InfoPostWebsiteEffects]),
    ],
    exports: [RouterModule]
})
export class InfopostModule { }
