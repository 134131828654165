import { IconPopoverOptions } from 'shared/components/table/dynamic-cell-components/icon-popover-cell/icon-popover-cell.interface';
import {
  applicationMenuConfig,
  InfoPostNavConfig,
  lowerInfoPostNavMenuConfigs,
} from '../../components/app-common-views/infopost-nav-container/sidebar-nav/nav-menus.constants';
import { LABELS } from '../../../shared/consts/labels.const';
import { InfoPostNotice } from '@gms/reporting-api';

export interface MappedDownloadIcon {
  pdfIcon: string;
  csvIcon: string;
}

export enum InformationalWebLabels {
  HOMELABEL = 'Home',
  DESCENDINGORDER = 'desc',
  SORTASCENDING = 'asc',
  PDF_ICON = 'icon-file-pdf-solid icon',
  CSV_ICON = 'icon-file-csv-solid icon',
  WORD_ICON = 'icon-file-word-solid icon',
  EXCEL_ICON = 'icon-file-excel-solid icon',
  COMMON_ICON = 'icon-file-lines-solid icon',
  CRITICALLABEL = 'Critical',
  PDFTOOLTIP = 'Click to download pdf',
  CSVTOOLTIP = 'Click to download csv',
  DOCTOOLTIP = 'Click to download doc',
  EXCELTOOLTIP = 'Click to download excel',
  COMMONTOOLTIP = 'Click to download',
  DESCRIPTIONDATAFIELD = 'description',
  DATETIMEDATAFIELD = 'datetimePostingEffectiveFormatted',
  ACTIONSDATAFIELD = 'actions',
  POSTINGHOMELABEL = 'Informational Postings',
  NONOTICETITLE = 'Notice',
  DOWNLOADTYPENOTICE = 'NOTICE',
  DOWNLOADTYPEPOSTING = 'POSTING',
  NONOTICELABEL = 'No Notice Activity',
  OPERATIONALLYLABEL = 'Operational Capacity',
  UNSUBSCRIBEDLABEL = 'Unsubscribed Capacity',
  UNSUBSCRIBED_FTS_ALABEL = 'Unsubscribed Capacity FTS-A',
  SCHEDULING_CONSTRAINEDLABEL = 'Scheduling & Constrained Areas',
  SCHEDULING_GROUPLABEL = 'Scheduling Group Available Capacity',
  DEPARTMENTALCONTACTS = 'Departmental Contacts',
  FORMS = 'Forms',
  GASQUALITYLABEL = 'Gas Quality',
  GASQUALITYPROCEDURESLABEL = 'Gas Quality Procedures',
  INDEXCUSTOMERSLABEL = 'Index of Customers',
  POSTEDIMBALANCESLABEL = 'Posted Imbalances',
  LOCATIONSLABEL = 'Location Data Download ',
  CAPACITYRELEASELABEL = 'Capacity Release ',
  FIRMSERVICESLABEL = 'Firm Services',
  INTERRUPTIBLETRANSPORTATIONLABEL = 'Interruptible Transportation',
  MANUALRELEASESLABEL = 'Manual Releases',
  PENDINGFILINGS = 'Pending Filings',
  SITE_MAP = 'Site Map',
  DOWNLOADSLABEL = 'Downloads',
  TRAININGLABEL = 'Training',
  DOWNLOADUNSUBSCRIBED_FTS = 'Unsubscribed FTS-A',
  STANDARDSOFCONDUCTLABEL = 'Standards of Conduct',
  TARIFF = 'Tariff',
  SEARCH = 'Search',
  TARIFFMAPLABEL = 'Tariff Maps',
  RATESLABEL = 'Rates',
  NONCRITICALLABEL = 'Non-Critical',
  PLANNEDSERVICEOUTAGES = 'Planned Service Outage',
  WASTEHEAT = 'Waste Heat',
  CAPACITYRELEASEINDICES = 'Capacity Release Indices',
  REQUESTTOPURCHASERELEASABLECAPACITY = 'Request by a potential Replacement Shipper to purchase releasable capacity from a potential Releasing Shipper.',
  COMMUNICATIONPROTOCOL = 'Communication Protocol',
  SAFEHARBORTARIFFPROVISION = 'Safe Harbor Tariff Provisions',
  POSTEDGASQUALITYSPECIFICATIONS = 'Posted Gas Quality Specifications',
  POSTING_HOME_PIPELINE_INFO = 'Pipeline Information :',
  POSTING_CRITICAL_NOTICE_INFO = 'Critical Notices and Planned Service Outages :',
  NOT_APPLIED_TSP_FOR_WEBSITE = `This does not apply to {tspName}`,
  POSTING_CRITICAL_PLANNED_SERVICE_CATEGORY = 'Critical|Planned Service Outage',
  DOCUMENTID_ISSUE = 'Document ID is not available',
  PDFICONCLASS = 'icon-file-pdf-solid',
  INFOPOST = 'INFOPOST',
  TSPNAME = '{tspName}',
  TARIFFDOWNLOAD = 'Tariff Download',
  NAESBCODE = 'NAESB Code Values',
  DISPLAYORDER = 'displayOrder',
  WEBSITEFEATUREFLAG = 'InfoPostWebApp',
  LOGINROUTES = '/login',
  CBHFEATUREFLAG = 'AllowCoastalBendHeaderWebApp',
  LEGACY_WEBSITE_URL = 'https://infopost.bwpipelines.com/TSPSelection.aspx',
  TARGET = '_blank',
  INFOPOSTROUTE = '/informational-posting',
  MAINPAGEROUTE = '/main',
  DATETIMEDATAFIELDSORTING = 'datetimePostingEffective',
}

export enum MenuItems {
  OPERATIONALCAPACITY = 1,
  UNSUBSCRIBEDCAPACITY = 2,
  NONOTICEACTIVITY = 3,
  GASQUALITY = 4,
  INDEXOFCUSTOMERS = 5,
  POSTEDIMBALANCES = 6,
  SCHEDULINGGROUPAVAILABLECAPACITY = 7,
  CAPACITYRELEASE = 8,
  LOCATIONDATADOWNLOAD = 9,
  FIRM_SERVICES = 10,
  INTERRUPTIBLETRANSPORTATION = 11,
  CONTRACTLETTERAGREEMENT = 12,
  PENDINGFILINGS = 13,
  FORMS = 14,
  PIPELINEINFORMATION = 15,
  RATES = 16,
  COMPLETEDTRADESSUPPLYLATCAPACITY = 17,
  REQUESTTOTRADESUPPLYLATCAPACITY = 18,
  TSPMAP = 19,
  BOARDWALKMAP = 20,
  BLANKETDISCOUNTS = 21,
  GENERALINFORMATION = 22,
  TARIFFDOWNLOAD = 23,
  TARIFFRATESCHEDULE = 24,
  TARIFFGENERALTERMSANDCONDITIONS = 25,
  TARIFFFORMSOFSERVICEAGREEMENTS = 26,
  TARIFFGASQUALITYTARIFFPROVISIONS = 27,
  SCHEDULINGCONSTRAINEDAREAS = 28,
  NAESBCODEVALUES = 29,
  COMPLAINTRESOLUTION = 30,
  DEPARTMENTALCONTACTS = 31,
  SERVICEREQUESTS = 32,
  MANUALRELEASES = 33,
  CAPACITYRELEASEAWARDNOTICES = 34,
  TARIFFTABLEOFCONTENTS = 35,
  TARIFFPRELIMINARYSTATEMENT = 36,
  TARIFFMAPS = 37,
  TARIFFCURRENTLYEFFECTIVERATES = 38,
  TARIFFSHEETSECTIONINDEX = 39,
  TARIFFTITLESHEETSECTION = 40,
  NOTICEATTACHMENT = 41,
  TSPMAPFOOTPRINT = 42,
  POSTEDGASQUALITYSPECIFICATIONS = 43,
  SAFEHARBORTARIFFPROVISIONS = 44,
  COMMUNICATIONPROTOCOL = 45,
  UNSUBSCRIBEDCAPACITYFTSA = 46,
  TRAINING = 47,
  NOTICES = 48,
  BUTANEINJECTIONDAILYREPORT = 49,
  BUTANEINJECTIONMONTHLYREPORT = 50,
  TRUCKUNLOADINGSUMMARY = 51,
  GASQUALITYPROCEDURE = 52,
  STANDARDCONDUCT = 53
}

export interface CapacityDataTable {
  id: string;
  description: string;
  postEffective: string;
  actions: {
    dynamicComponentType: any;
    data: IconPopoverOptions;
  };
}

export function getInfoPostMenuConfigItems(
  menuType: string,
  providerId: number
): IInfoPostMenuConfigModel {
  let upperConfigMain = applicationMenuConfig.filter(
    (x) => x.refID === LABELS.MENU_TYPE_INFOPOST && x?.tspApplicable?.includes(providerId)
  );

  let upperConfigSub = applicationMenuConfig
    .filter((x) => x.refID === LABELS.MENU_TYPE_INFOPOST)
    .map((x) => ({
      ...x,
      subMenuItems: x?.subMenuItems?.filter((y) => y.tspApplicable.includes(providerId)) || []
    }))
    .filter((x) => x.subMenuItems.length > 0);

  let lowerConfigMain = lowerInfoPostNavMenuConfigs.filter(
    (x) => x.refID === LABELS.MENU_TYPE_INFOPOST && x?.tspApplicable?.includes(providerId)
  );

  let lowerConfigSub = lowerInfoPostNavMenuConfigs
    .filter((x) => x.refID === LABELS.MENU_TYPE_INFOPOST)
    .filter((x) => {
      return x?.subMenuItems?.some((y) => y.tspApplicable.includes(providerId));
    });
  const idsToRemove = ['rates', 'NAESB-code-values'];
  return {
    lowerConfig: [...lowerConfigMain, ...lowerConfigSub],
    upperConfig: [...upperConfigMain, ...upperConfigSub].filter(
      (item) => !idsToRemove.includes(item.id)
    ),
  };
}

export function getDownloadMenuConfigItems(menuType: string, providerId: number) {
  let upperNavMenuConfigsMain = applicationMenuConfig
    .filter((x) => !x.subMenuItems || x.subMenuItems.length === 0)
    .filter((x) => x.refID === LABELS.MENU_TYPE_INFOPOST && x.tspApplicable?.includes(providerId));

  let upperNavMenuConfigsSub = applicationMenuConfig
    .filter(
      (x) => x.subMenuItems && x.subMenuItems.length > 0 && x.refID === LABELS.MENU_TYPE_INFOPOST
    )
    .map((data) => ({
      ...data,
      subMenuItems: data.subMenuItems.filter((sub) => sub.tspApplicable?.includes(providerId)),
    }));

  const data: any = [...upperNavMenuConfigsMain, ...upperNavMenuConfigsSub];
  const modifiedData = flattenMenuItems(data);
  return {
    upperConfig: modifiedData,
  };
}

export interface IInfoPostMenuConfigModel {
  upperConfig: Array<InfoPostNavConfig>;
  lowerConfig: Array<InfoPostNavConfig>;
}

const flattenMenuItems = (menuItems) => {
  return menuItems
    .flatMap((item) => {
      if (item.subMenuItems) {
        return item.subMenuItems
          .filter((subItem) => subItem.isapplicableDownload)
          .map((subItem) => ({
            ...subItem,
            icon: item.icon,
            refID: item.refID,
            // displayOrder: item.displayOrder,
          }));
      }
      return item.isapplicableDownload ? [item] : [];
    })
    .filter((item) => item.isapplicableDownload);
};
export interface InfoPostNoticeAdditionalDetails extends InfoPostNotice {
  dateTimePostingFormatted?: string;
  dateTimeNoticeEffectiveFormatted?: string;
  dateTimeNoticeEndFormatted?: string;
  dateTimeResponseRequiredFormatted?: string;
}
export class InfoPostNoticeAdditionalDetailsList extends Array<InfoPostNoticeAdditionalDetails> { }

export const formatDateTime = (dateTime: string, hour12: boolean): string => {
  // Format the date as MM/DD/YYYY and time as hh:mm AM/PM
  const date = new Date(dateTime);

  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: hour12,
  });

  return formattedDate.replace(',', '');
}

export const NoticeType = {
  RELEASE_CAPACITY_NOTICE_TYPE_ID: 59,
  RELEASE_CAPACITY_NOTICE_TYPE_LABEL: 'Request to Purchase Releasable Capacity'
}

export const NoticeCategory = {
  critical: 'Critical',
  nonCritical: 'Non-Critical',
  plannedServiceOutage: 'Planned Service Outage',
  wasteHeat: 'Waste Heat'
}